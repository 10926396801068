<template>
  <div v-if="ficha && ficha.validaciones">
    Actualizaciones del usuario
    <div v-for="(i, k) in ficha.validaciones" :key="'val' + i">
      {{ ficha.validaciones[k].empact_fhultact }}<br />
    </div>

    <div class="mt-4">
      <strong>Última validación</strong>
      <div v-if="ficha.validaciones.length && ficha.validaciones[0].empval_fh">
        {{ ficha.validaciones[0].empval_fh }} <br />
        {{ ficha.validaciones[0].empval_codigo }} <br />
        {{ ficha.validaciones[0].empval_validador }} <br />
      </div>
      <div v-else>No hay ninguna validación de datos registrada</div>
    </div>

    <b-button class="mt-4 btn btn-manusa" @click="marcardatosguardados"
      >Marcar - datos guardados en LABOR</b-button
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "MiEspacioActualizacionesValidaciondatos",
  props: {
    ficha: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      token: localStorage.getItem("token"),
    };
  },
  computed: {
    ...mapState(["usuario"]),
  },
  methods: {
    async marcardatosguardados() {
      console.log("inicio marcardatosguardados");
      const MIESPACIOURL = process.env.VUE_APP_HOSTPYAPI + "/accion";
      const MIESPACIOCONFIG = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      const PARAMS = {
        accion: "marcar-datos-guardados-en-labor",
        contexto: "hrpeople",
        params: {
          codigo: this.ficha.emp_codigo,
          validador: this.usuario.emailmanusa,
        },
      };
      try {
        let res = await axios.post(MIESPACIOURL, PARAMS, MIESPACIOCONFIG);
        if ("error" in res.data && res.data.error) {
          console.log("ERROR llamada", res.data.error);
        } else {
          this.$emit("datos-actualizados")
        }
      } catch (error) {
        console.log("ERROR llamada", error);
      }
    },
  },
};
</script>

<style></style>
