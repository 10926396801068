<template>
  <b-row v-if="campo" :ref="campo.codigo" class="campo">
    <b-col cols="3" class="text-right">{{ campo.nombre }}</b-col>
    <b-col cols="1"
      ><b-icon
        :class="campo.valorcambiado ? 'text-danger' : 'text-success'"
        :icon="campo.valorcambiado ? 'x-circle' : 'check-circle'"
      ></b-icon
    ></b-col>
    <b-col>
      <input
        readonly
        size="80"
        v-if="campo.valoractualizado !== ''"
        @focus="onSeleccionarycopiar"
        :value="campo.valoractualizado"
      />
      <div v-else>(vacío)</div>

      <div>
        <small v-show="campo.valorcambiado"
          >(Antes: '{{ campo.valororiginal }}')</small
        >
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    campo: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onSeleccionarycopiar(e) {
      e.target.select();
      document.execCommand("copy");
    },
  },
};
</script>

<style scoped>
.campo {
  border-top: 1px solid rgb(231, 231, 231);
  padding: 2px 0;
}
input,
input:hover,
input:active,
input:focus {
  border: 0 !important;
  outline: none !important;
}
</style>
