<template>
  <div v-if="ficha">
    <b-row
      ><b-col cols="3" class="text-right">Situación familiar</b-col
      ><b-col>{{ ficha.empact_situacionfamiliardescripcion }}</b-col></b-row
    >
    <b-row
      ><b-col cols="3" class="text-right">Obtención rendimiento</b-col
      ><b-col>{{
        ficha.empact_obtencionrendimiento === "1" ? "Sí" : "No"
      }}</b-col></b-row
    >
    <b-row
      ><b-col cols="3" class="text-right">Grado discapacidad</b-col
      ><b-col>{{ ficha.empact_gradodiscapacidaddescripcion }}</b-col></b-row
    >
    <b-row
      ><b-col cols="3" class="text-right">Movilidad geográfica</b-col
      ><b-col>{{
        ficha.empact_movilidadgeografica.slice(0, 4) === "1900"
          ? "-"
          : ficha.empact_movilidadgeografica.slice(0, 10)
      }}</b-col></b-row
    >
    <b-row
      ><b-col cols="3" class="text-right">NIF cónyuge</b-col
      ><b-col>{{ ficha.empact_nifconyuge }}</b-col></b-row
    >
    <b-row
      ><b-col cols="3" class="text-right"
        >Pensiones compensatorias cónyuge</b-col
      ><b-col>{{ ficha.empact_pensionescompensatoriasconyuge }}</b-col></b-row
    >
    <b-row
      ><b-col cols="3" class="text-right">Pensiones compensatorias hijos</b-col
      ><b-col>{{ ficha.empact_pensionescompensatoriashijos }}</b-col></b-row
    >
    <b-row
      ><b-col cols="3" class="text-right">Pagos vivienda</b-col
      ><b-col>{{
        ficha.empact_pagosvivienda === "1" ? "Sí" : "No"
      }}</b-col></b-row
    >
    <b-row
      ><b-col cols="3" class="text-right">Necesidad ayuda discapacidad</b-col
      ><b-col>{{
        ficha.empact_necesidadayudadiscapacidad === "1" ? "Sí" : "No"
      }}</b-col></b-row
    >

    <div class="mt-3">
      <h5>ASCENDIENTES ({{ ficha.empact_numerodeascdep }})</h5>
      <b-table
        v-show="ficha.empact_numerodeascdep > 0"
        :items="ficha.ascendientes"
        :fields="fieldsasc"
      ></b-table>
    </div>
    <div class="mt-3">
      <h5>DESCENDIENTES ({{ ficha.empact_numhijos }})</h5>
      <b-table
        v-show="ficha.empact_numhijos > 0"
        :items="ficha.descendientes"
        :fields="fieldsdesc"
      ></b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "MiEspacioActualizacionesModelo145",
  props: {
    ficha: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      fieldsasc: [
        { key: "empaact_n", label: "#" },
        { key: "empaact_nombre", label: "Nombre" },
        { key: "empaact_annonacimiento", label: "Año nacimiento" },
        {
          key: "empaact_gradodiscapacidaddescripcion",
          label: "Grado discapacidad",
        },
        {
          key: "empaact_gradodiscapacidadnecesitaayuda",
          label: "Necesita ayuda",
        },
        { key: "empaact_ccod", label: "CCOD" },
      ],
      fieldsdesc: [
        { key: "emphact_n", label: "#" },
        { key: "emphact_nombre", label: "Nombre" },
        { key: "emphact_annonacimiento", label: "Año nacimiento" },
        { key: "emphact_annoadopcion", label: "Año adopción" },
        {
          key: "emphact_gradodiscapacidaddescripcion",
          label: "Grado discapacidad",
        },
        {
          key: "emphact_gradodiscapacidadnecesitaayuda",
          label: "Necesita ayuda",
        },
        {
          key: "emphact_cpe",
          label: "CPE",
          formatter: (value) => {
            return value === "1" ? "Sí" : "No";
          },
        },
      ],
    };
  },
};
</script>

<style></style>
