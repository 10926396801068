<template>
  <b-container fluid id="contenido-pagina">
    <div v-if="autenticado && accesopermitido">
      <b-overlay :show="!datoscargados" rounded="sm">
        <b-row>
          <b-col cols="2"></b-col>
          <b-col cols="8">
            <h1>
              Empleados que han actualizado alguno de sus datos en MiEspacio
            </h1>
            <b-form-group label="Buscar usuario" label-cols="2">
              <b-form-input
                v-model="btxt"
                @keyup.enter="onBuscar"
              ></b-form-input>
            </b-form-group>
            <b-table
              v-if="itemsfiltrados && itemsfiltrados.length"
              :items="itemsfiltrados"
              :fields="fields"
              hover
              striped
              :busy="!datoscargados"
              sort-by="emp_codigo"
            >
              <template #cell(nomape)="data">
                {{ data.item.emp_primerapellido }}
                {{ data.item.emp_segundoapellido }}, {{ data.item.emp_nombre }}
              </template>
              <template #cell(ultact)="data">
                {{ data.item.empact_fhultact.slice(0, 16) }}
              </template>
              <template #cell(detalle)="data">
                <b-icon-zoom-in @click="onVerDetalle(data)"></b-icon-zoom-in>
              </template>
            </b-table>
          </b-col>
          <b-col cols="2">
            <b-button
              v-if="listadonoactualizadoscargado"
              class="btn btn-manusa"
              @click="onListadonoactualizados"
            >
              ¿Quién no ha actualizado sus datos (en MiEspacio)?<br /><span class="font-weight-bold">{{
                listadonoactualizados.length
              }} personas</span>
              
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
      <b-modal
        id="bv-modal-listadonoactualizados"
        size="xl"
        title="Empleados que no han actualizado sus datos en MiEspacio"
        hide-footer
      >
        <b-overlay :show="!listadonoactualizadoscargado" rounded="sm">
          <b-table
            :items="listadonoactualizados"
            :fields="listadonoactualizadosfields"
          ></b-table>
        </b-overlay>
      </b-modal>
      <b-modal
        id="bv-modal-fichaactualizaciones"
        size="xl"
        :title="tituloficha"
        hide-footer
      >
        <b-overlay :show="partescargadasdeficha < 2" rounded="sm">
          <b-card no-body>
            <b-tabs card>
              <b-tab title="Datos personales" active>
                <MiEspacioActualizacionesCampo
                  v-for="c in camposcambiables"
                  :key="c.codigo"
                  :campo="c"
                ></MiEspacioActualizacionesCampo>
              </b-tab>
              <b-tab title="Modelo 145">
                <MiEspacioActualizacionesModelo145
                  v-if="ficha && partescargadasdeficha === 3"
                  :ficha="ficha"
                ></MiEspacioActualizacionesModelo145>
              </b-tab>
              <b-tab title="Validación de datos - LABOR">
                <MiEspacioActualizacionesValidaciondatos
                  v-if="ficha && partescargadasdeficha === 3"
                  :ficha="ficha"
                  @datos-actualizados="onRecargarFicha"
                ></MiEspacioActualizacionesValidaciondatos>
              </b-tab>
            </b-tabs> </b-card
        ></b-overlay>
        <hr />
        <b-button @click="hideModal" class="text-right">Cerrar</b-button>
      </b-modal>
    </div>
    <div v-else-if="autenticado && !accesopermitido">
      No tienes acceso a esta sección
    </div>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
// axios.defaults.baseURL = process.env.VUE_APP_HOSTPYAPI;
import MiEspacioActualizacionesCampo from "@/components/MiEspacioActualizacionesCampo.vue";
import MiEspacioActualizacionesModelo145 from "@/components/MiEspacioActualizacionesModelo145.vue";
import MiEspacioActualizacionesValidaciondatos from "@/components/MiEspacioActualizacionesValidaciondatos.vue";

export default {
  name: "MiEspacioActualizaciones",
  components: {
    MiEspacioActualizacionesCampo,
    MiEspacioActualizacionesModelo145,
    MiEspacioActualizacionesValidaciondatos,
  },
  data() {
    return {
      listadonoactualizadoscargado: false,
      listadonoactualizados: [],
      listadonoactualizadosfields: [
        { key: "emp_codigo", label: "Código", sortable: true },
        { key: "emp_emailempresa", label: "Email", sortable: true },
        { key: "emp_nombre", label: "Nombre", sortable: true },
        { key: "emp_primerapellido", label: "Pr.Apellido", sortable: true },
        {
          key: "emppt_centrotrabajo",
          label: "Centro de trabajo",
          sortable: true,
        },
        { key: "emppt_puestotrabajo", label: "Puesto", sortable: true },
      ],
      btxt: null,
      token: localStorage.getItem("token"),
      datoscargados: false,
      partescargadasdeficha: 0,
      ficha: null,
      items: null,
      itemsfiltrados: null,
      fields: [
        {
          key: "emp_codigo",
          label: "Código",
          sortable: true,
        },
        {
          key: "nomape",
          label: "Nombre y apellidos",
          sortable: true,
          sortByFormatted: true,
          formatter: (v, k, it) => {
            return (
              it.emp_primerapellido +
              " " +
              it.emp_segundoapellido +
              ", " +
              it.emp_nombre
            );
          },
        },
        {
          key: "ultact",
          label: "Actualización",
          sortable: true,
          sortByFormatted: true,
          formatter: (v, k, it) => {
            return it.empact_fhultact.slice(0, 16);
          },
        },
        { key: "validado", label: "Validado" },
        { key: "detalle", label: "" },
      ],
      camposcambiables: [
        {
          codigo: "nombre",
          nombre: "Nombre",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "primerapellido",
          nombre: "Primer apellido",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "segundoapellido",
          nombre: "Segundo apellido",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "fechanacimiento",
          nombre: "Fecha Nacimiento",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "paisnacimiento",
          nombre: "País Nacimiento",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "nacionalidad",
          nombre: "Nacionalidad",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "siglasdomfis",
          nombre: "Siglas dom.fis.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "domiciliofis",
          nombre: "Domicilio fis.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "casafis",
          nombre: "Casa fis.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "escalerafis",
          nombre: "Escalera fis.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "pisofis",
          nombre: "Piso fis.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "puertafis",
          nombre: "Puerta fis.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "codpoblacionfis",
          nombre: "Cod.población fis.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "poblacionfis",
          nombre: "Poblacion fis.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "provinciafis",
          nombre: "Provincia fis.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "telefonopral",
          nombre: "Teléfono ppal.",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "telefonocontacto",
          nombre: "Teléfono contacto",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "personacontacto",
          nombre: "Persona contacto",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "telefonoadicional",
          nombre: "Teléfono adicional",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "nombremadre",
          nombre: "Nombre madre",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "nombrepadre",
          nombre: "Nombre padre",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "nass",
          nombre: "NASS",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "estudios",
          nombre: "Estudios",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "codnivelformativodescripcion",
          nombre: "Cod.nivel formativo",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "titulomaster",
          nombre: "Título master",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "vehiculopart1",
          nombre: "Vehiculo part.1",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "vehiculopart2",
          nombre: "Vehiculo part.2",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "parkingasignado",
          nombre: "Parking asignado",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "cuentabancaria1",
          nombre: "Cuenta bancaria",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "movilempresa",
          nombre: "Móvil empresa",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "numeromovilempresa",
          nombre: "Número móvil empresa",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
        {
          codigo: "emailpersonal",
          nombre: "Email personal",
          valororiginal: "",
          valoractualizado: "",
          valorcambiado: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["usuario"]),
    ...mapGetters(["autenticado"]),
    tituloficha() {
      return this.ficha
        ? this.ficha.emp_codigo +
            " - " +
            this.ficha.emp_primerapellido +
            " " +
            this.ficha.emp_segundoapellido +
            ", " +
            this.ficha.emp_nombre
        : "";
    },
    accesopermitido() {
      if (!this.usuario) return false;
      return (
        this.usuario.permisos.rrhh.rol === "administrador" ||
        this.usuario.permisos.rrhh.contextos.indexOf("manuseros.readwrite") >= 0
      );
    },
  },
  created() {
    setTimeout(() => {
      this.inicio();
    }, 500);
  },
  methods: {
    onListadonoactualizados() {
      this.$bvModal.show("bv-modal-listadonoactualizados");
    },
    async cargarListadonoactualizados() {
      this.listadonoactualizadoscargado = false;
      const MIESPACIOURL = process.env.VUE_APP_HOSTPYAPI + "/accion";
      const MIESPACIOCONFIG = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      const PARAMS = {
        accion: "obtener-empleados-sin-actualizar-miespacio",
        contexto: "hrpeople",
        params: {},
      };
      try {
        let res = await axios.post(MIESPACIOURL, PARAMS, MIESPACIOCONFIG);
        console.log("cargarListadonoactualizados", res.data);
        if ("error" in res.data && res.data.error) {
          console.log(
            "ERROR llamada cargarListadonoactualizados",
            res.data.error
          );
        } else {
          this.listadonoactualizados = res.data.resultado;
        }
        this.listadonoactualizadoscargado = true;
      } catch (error) {
        console.log("ERROR llamada cargarListadonoactualizados", error);
      }
    },
    quitaracentos(txt) {
      return txt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    onBuscar() {
      if (this.btxt) {
        let btxt = this.quitaracentos(this.btxt.toLowerCase());
        this.itemsfiltrados = this.items.filter((it) => {
          return it["txtbusqueda"].indexOf(btxt) >= 0;
        });
      } else {
        this.itemsfiltrados = this.items.filter(() => {
          return true;
        });
      }
    },
    inicio() {
      if (this.usuario) {
        if (this.accesopermitido) {
          this.cargardatos();
          this.cargarListadonoactualizados();
          setInterval(() => {
            this.cargardatos();
            this.cargarListadonoactualizados();
          }, 60000);
        }
      } else {
        setTimeout(() => {
          this.inicio();
        }, 500);
      }
    },
    hideModal() {
      this.$bvModal.hide("bv-modal-fichaactualizaciones");
      this.partescargadasdeficha = 0;
      this.ficha = null;
    },
    onRecargarFicha() {
      this.$bvModal.hide("bv-modal-fichaactualizaciones");
      this.cargardatos();
      // this.$bvModal.hide("bv-modal-fichaactualizaciones");
      // this.partescargadasdeficha--;
      // this.cargarficha("validaciones");
      // this.$bvModal.show("bv-modal-fichaactualizaciones");
    },
    onVerDetalle(d) {
      this.ficha = null;
      this.partescargadasdeficha = 0;
      console.log(d.item);
      this.camposcambiables.forEach((c) => {
        c["valororiginal"] = d.item["emp_" + c.codigo];
        c["valoractualizado"] = d.item["empact_" + c.codigo];
        c["valorcambiado"] = d.item[c.codigo + "cambiado"] === "1";
      });
      this.ficha = d.item;
      this.$bvModal.show("bv-modal-fichaactualizaciones");
      this.cargarficha("ascendientes");
      this.cargarficha("descendientes");
      this.cargarficha("validaciones");
    },
    async cargarficha(parte) {
      const MIESPACIOURL = process.env.VUE_APP_HOSTPYAPI + "/accion";
      const MIESPACIOCONFIG = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      const PARAMS = {
        accion: "obtener-mi-espacio-actualizaciones-ficha",
        contexto: "hrpeople",
        params: { codigo: this.ficha.emp_codigo, parte: parte },
      };
      var respuesta = { error: null };
      try {
        let res = await axios.post(MIESPACIOURL, PARAMS, MIESPACIOCONFIG);
        console.log("cargardatos", res.data);
        if ("error" in res.data && res.data.error) {
          console.log("ERROR llamada ficha-" + parte, res.data.error);
          respuesta.error = {
            code: 99990,
            message: "ERROR llamada ficha-" + parte,
            info: res.data.error,
          };
          if (res.data.error === "ERRORCONEXIONDB") {
            document.location.href = "/error-conexion";
          }
        } else {
          this.ficha[parte] = res.data.resultado;
          this.partescargadasdeficha++;
        }
      } catch (error) {
        console.log("ERROR llamada ficha-" + parte, error);
        respuesta = {
          error: {
            code: 99990,
            message: "ERROR llamada ficha-" + parte,
            info: error,
          },
        };
      }
    },
    async cargardatos() {
      this.items = null;
      const MIESPACIOURL = process.env.VUE_APP_HOSTPYAPI + "/accion";
      const MIESPACIOCONFIG = {
        headers: {
          Authorization: `Bearer ${this.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      const PARAMS = {
        accion: "obtener-mi-espacio-actualizaciones",
        contexto: "hrpeople",
        params: null,
      };
      var respuesta = { error: null };
      this.datoscargados = false;
      try {
        let res = await axios.post(MIESPACIOURL, PARAMS, MIESPACIOCONFIG);
        console.log("cargardatos", res.data);
        if ("error" in res.data && res.data.error) {
          console.log("ERROR llamada cargardatos", res.data.error);
          respuesta.error = {
            code: 99990,
            message: "ERROR llamada cargardatos",
            info: res.data.error,
          };
          if (res.data.error === "ERRORCONEXIONDB") {
            document.location.href = "/error-conexion";
          }
        } else {
          this.items = res.data.resultado;
          this.items.forEach((it) => {
            it["txtbusqueda"] =
              it["emp_codigo"] +
              "." +
              this.quitaracentos(it["emp_nombre"].toLowerCase()) +
              "." +
              this.quitaracentos(it["emp_primerapellido"].toLowerCase()) +
              "." +
              this.quitaracentos(it["emp_segundoapellido"].toLowerCase());
            it["empact_fechanacimiento"] = it["empact_fechanacimiento"].slice(
              0,
              10
            );
            switch (it["empact_situacionfamiliar"]) {
              case "1":
                it["empact_situacionfamiliardescripcion"] =
                  "Soltero/a, viudo/a, divorciado/a o separado/a legalmente con hijos solteros menores de 18 años o incapacitados judicialmente y sometidos a patria protestad rorrogada o rehabilitada que conviven exclusivamente con Vd., sin convivir también con el otro progenitor, siempre que proceda consignar al menos un hijo o descendiente en el apartado 2 de este documento";
                break;
              case "2":
                it["empact_situacionfamiliardescripcion"] =
                  "Casado/a y no separado/a legalmente y mi cónyuge no obtiene rentas superiores a 1.500 euros anuales, excluidas las exentas";
                break;
              case "3":
                it["empact_situacionfamiliardescripcion"] =
                  "Situación familiar distinta de las dos anteriores (Marca también esta casilla si no deseas manifestar tu situación familiar)";
                break;
            }
            switch (it["empact_necesidadayudadiscapacidad"]) {
              case "0":
                it["empact_necesidadayudadiscapacidaddescripcion"] = "Ninguna";
                break;
              case "1":
                it["empact_necesidadayudadiscapacidaddescripcion"] =
                  "Igual o superior al 33% e inferior al 65%";
                break;
              case "2":
                it["empact_necesidadayudadiscapacidaddescripcion"] =
                  "Igual o superior al 65%";
                break;
            }
          });
        }
        this.onBuscar();
        this.datoscargados = true;
      } catch (error) {
        console.log("ERROR llamada cargardatos", error);
        respuesta = {
          error: {
            code: 99990,
            message: "ERROR llamada cargardatos",
            info: error,
          },
        };
        this.datoscargados = true;
      }

      // const uri = "/accion";
      // console.log(process.env.VUE_APP_HOSTPYAPI, uri, json);
      // var _t = this;
      // axios
      //   .post(uri, json, {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${this.token}`,
      //     },
      //   })
      //   .then((res) => {
      //     if ("error" in res.data && res.data.error !== null) {
      //       if (res.data.error === "ERRORCONEXIONDB")
      //         document.location.href = "/error-conexion";
      //     } else {
      //       _t.datoscargados = true;
      //       console.log("respuesta", res.data);
      //     }
      //   })
      //   // .catch(function (error) {
      //   //   console.log(error);
      //   // })
      //   .then(function () {
      //     _t.datoscargados = true;
      //   });
    },
  },
};
</script>

<style></style>
